import React, {createContext, useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useInfoViewActionsContext} from '@crema/context/AppContextProvider/InfoViewContextProvider';
import {isNullOrEmpty} from '@crema/helpers/Common';
import {AppStateConstant} from '@crema/constants/AppState';
// import {signInApi, getUserInfo} from './AuthApi';
// import {useAxiosInterceptor} from '@crema/services/axios/ApiConfig';
import {setAuthServerToken} from '../api/auth-axios';
import {useSearchParams} from 'react-router-dom';
import {useAPIMethodGet} from '@crema/hooks/APIHooks';
import {LOGIN_URL} from '@crema/constants/AppConfig';
const DefaultAuthContext = createContext({});
const AuthActionsContext = createContext({});
export const AppStateContext = createContext({});

export const useDefaultAuthContext = () => useContext(DefaultAuthContext);
export const useAuthActionContext = () => useContext(AuthActionsContext);
const convertUserState = (userInfo) => ({
  ...userInfo,
  userRoles: userInfo?.authorities?.map((item) => item.authority) || [],
  phoneNumber: userInfo?.moreInfo?.phoneNumber,
  contactEmail: userInfo?.moreInfo?.contactEmail,
});
const AuthProvider = ({children}) => {
  //how to logout
  const [searchParams] = useSearchParams();
  const [severToken, setSeverToken] = useState(null);

  //   const {isIntercepted} = useAxiosInterceptor(severToken);
  const [dataLogin, setDataLogin] = useState({
    isAuthenticated: false,
    isLoading: false,
  });
  const [appState, setAppState] = useState({});
  const url = LOGIN_URL.USER_INFO;
  const logout = () => {
    // eslint-disable-next-line no-debugger
    setTimeout(() => {
      localStorage.removeItem(AppStateConstant.ServerToken);
      localStorage.removeItem(AppStateConstant.appState);
    }, 2000);
    // setDataLogin({
    //   isAuthenticated: false,
    //   isLoading: false,
    // });
    // setAppState({});
  };
  const {
    data: dataUserProfile,
    loading: loadingUserProfile,
    error: errorUserProfile,
    recallApi: recallApiUserProfile,
  } = useAPIMethodGet(
    url,
    false,
    (error) => {
      console.log(error);
    },
    (response) => {
      console.log(response);
    },
  );
  useEffect(() => {
    const token = searchParams.get('token');
    const localStorageToken = localStorage.getItem(
      AppStateConstant.ServerToken,
    );
    if (token && localStorageToken) {
      if (token === localStorageToken) {
        setAuthServerToken(localStorageToken);
        const appStatStorage = localStorage.getItem(AppStateConstant.appState);
        setAppState(JSON.parse(appStatStorage));
        setDataLogin({
          isAuthenticated: true,
          isLoading: false,
        });
      } else {
        reLogIn();
      }
    } else if (localStorageToken) {
      setAuthServerToken(localStorageToken);
      const appStatStorage = localStorage.getItem(AppStateConstant.appState);
      setAppState(JSON.parse(appStatStorage));
      setDataLogin({
        isAuthenticated: true,
        isLoading: false,
      });
    } else {
      return;
    }
    return () => {
      console.log('111111');
    };
  }, []);

  useEffect(() => {
    if (severToken) {
      recallApiUserProfile();
    }
  }, [severToken]);

  useEffect(() => {
    if (dataUserProfile) {
      setDataLogin({
        isLoading: false,
        isAuthenticated: true,
      });
      setAppState({
        ...appState,
        idToken: severToken,
        serverToken: severToken,
        user: {
          ...convertUserState(dataUserProfile),
        },
      });
      localStorage.setItem(
        AppStateConstant.appState,
        JSON.stringify({
          ...appState,
          idToken: severToken,
          serverToken: severToken,
          user: {
            ...convertUserState(dataUserProfile),
          },
        }),
      );
    }
  }, [dataUserProfile]);

  useEffect(() => {
    if (errorUserProfile) {
      setDataLogin({
        isLoading: false,
        isAuthenticated: false,
      });
    }
  }, [errorUserProfile]);

  const signIn = () => {
    const token = searchParams.get('token');
    setDataLogin({...dataLogin, isLoading: true});
    try {
      if (!token) {
        setDataLogin({...dataLogin, isLoading: false});
        return;
      } else {
        setSeverToken(token);
        setAuthServerToken(token);
        localStorage.setItem(AppStateConstant.ServerToken, token);
      }
    } catch {
      setDataLogin({
        isLoading: false,
        isAuthenticated: false,
      });
      setAppState({});
    }
  };

  const reLogIn = () => {
    localStorage.removeItem(AppStateConstant.ServerToken);
    localStorage.removeItem(AppStateConstant.appState);
    signIn();
  };

  return (
    <AppStateContext.Provider value={appState}>
      <DefaultAuthContext.Provider
        value={{
          ...dataLogin,
          ...appState,
        }}
      >
        <AuthActionsContext.Provider
          value={{
            logout,
            signIn,
            reLogIn,
          }}
        >
          {children}
        </AuthActionsContext.Provider>
      </DefaultAuthContext.Provider>
    </AppStateContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
