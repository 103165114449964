import styled from 'styled-components';

export const StyledAppLogo = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  & img {
    height: 40px;
    margin-right: 10px;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;
export const StyleAppLogoText = styled.div`
  font-size: 20px;
  color: var(--primary);
  font-weight: bold;
`;
