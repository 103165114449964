import {APP_CONFIG} from '@crema/constants/AppConfig';
import {AppStateConstant} from '@crema/constants/AppState';
import axios, {AxiosResponse} from 'axios';

const instance = axios.create({
  baseURL: APP_CONFIG.HOST_NAME, // YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  function (error) {
    if (error.response) {
      return Promise.reject(error);
      // switch (error.response?.status) {
      //   case 400:
      //     return Promise.reject(error);
      //   case 401:
      //     return Promise.reject(error);
      //   // return this.handle401Error(request, next, error);
      //   case 500:
      //     return Promise.reject(error);
      //   case 403:
      //     return Promise.reject(error);
      //   default:
      //     return Promise.reject(error);
      // }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);
export const setAuthServerToken = (token?: string) => {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    const serverToken = localStorage.getItem(AppStateConstant.ServerToken);
    instance.defaults.headers.common.Authorization = `Bearer ${serverToken}`;
  }
};
export default instance;
