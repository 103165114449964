import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import ThemeContextProvider from './ThemeContextProvider';
import LocaleContextProvider from './LocaleContextProvider';
import LayoutContextProvider from './LayoutContextProvider';
import SidebarContextProvider from './SidebarContextProvider';
import InfoViewContextProvider from './InfoViewContextProvider';
import {CustomModalGlobalContext} from './CustomModalGlobalProvider';
import AppMessageModal from '@crema/components/AppMessageModal';
import {IConFirmModalProps} from '@crema/components/AppConfirmModal/AppConfirmModalModel';
import {AppStateConstant} from '@crema/constants/AppState';
// import {
//   CustomModalGlobalContext,
//   CustomModalGlobalProvider,
// } from './CustomModalGlobalProvider';

type AppContextProviderProps = {
  children: ReactNode;
};

const AppContextProvider: React.FC<AppContextProviderProps> = ({children}) => {
  const [paramsMessage, setParamsMessage] = useState<IConFirmModalProps>({
    title: '',
    message: '',
    isEnableModal: false,
  });
  const {isShowModal, setIsShowModal, message} = useContext<any>(
    CustomModalGlobalContext,
  );
  useEffect(() => {
    if (!isShowModal) return;
    setParamsMessage({
      title: message?.title,
      message: message.message,
      isEnableModal: isShowModal,
    });
  }, [isShowModal]);
  const handleModalResult = (result: any) => {
    setIsShowModal(false);
  };
  return (
    <ThemeContextProvider>
      <LocaleContextProvider>
        <InfoViewContextProvider>
          <LayoutContextProvider>
            <SidebarContextProvider>{children}</SidebarContextProvider>
          </LayoutContextProvider>
        </InfoViewContextProvider>
      </LocaleContextProvider>
      {isShowModal && (
        <AppMessageModal
          paramsModal={paramsMessage}
          callback={handleModalResult}
        />
      )}
    </ThemeContextProvider>
  );
};

export default AppContextProvider;
