import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const HealthCare = React.lazy(
  () => import('../../../modules/dashboards/HealthCare'),
);
const ECommerce = React.lazy(
  () => import('../../../modules/dashboards/ECommerce'),
);
const CRM = React.lazy(() => import('../../../modules/dashboards/CRM'));
const Crypto = React.lazy(() => import('../../../modules/dashboards/Crypto'));
const Analytics = React.lazy(
  () => import('../../../modules/dashboards/Analytics'),
);
const Academy = React.lazy(() => import('../../../modules/dashboards/Academy'));
const Metrics = React.lazy(() => import('../../../modules/dashboards/Metrics'));
const Widgets = React.lazy(() => import('../../../modules/dashboards/Widgets'));

export const dashboardConfig = [
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/health-care',
    element: <HealthCare />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/e-commerce',
    element: <ECommerce />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/crm',
    element: <CRM />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/crypto',
    element: <Crypto />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/analytics',
    element: <Analytics />,
  },

  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/academy',
    element: <Academy />,
  },

  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/metrics',
    element: <Metrics />,
  },

  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/dashboards/widgets',
    element: <Widgets />,
  },
];
