export const MOMENT_DATE_FORMAT: any = {
  // day of month (2 digits), month (2 digits) and year (4 digits).
  DATE: 'L',
  // short day of month, short month and year (4 digits).
  SHORT_DATE: 'l',

  // hour and minute.
  TIME: 'LT',
  // hour, minute and second.
  TIME_WITH_SECOND: 'LTS',

  // day of month (2 digits), month (2 digits) and year (4 digits).
  DATE_TIME: 'L LTS',
  // short day of month, short month and year (4 digits).
  SHORT_DATE_TIME: 'l LTS',

  // day of month (2 digits), month name and year (4 digits).
  DATE_WITH_MONTH_NAME: 'LL',
  // short day of month, short month name and year (4 digits).
  SHORT_DATE_WITH_MONTH_NAME: 'll',

  // day of month, month name, year (4 digits) and time. September 4, 1986
  DATE_TIME_WITH_MONTH_NAME: 'LLL',
  // short day of month, short month name, year (4 digits) and time. Sep 4, 1986
  SHORT_DATE_TIME_WITH_MONTH_NAME: 'lll',

  // day of week, day of month, month name, year (4 digits) and time. Thursday, September 4, 1986 8:30 PM
  DATE_TIME_WITH_MONTH_NAME_AND_DAY_OF_WEEK: 'LLLL',
  // short day of week, short day of month, short month name, year (4 digits) and time. Thu, Sep 4, 1986 8:30 PM
  SHORT_DATE_TIME_WITH_MONTH_NAME_AND_DAY_OF_WEEK: 'llll',

  FULL_TIME: 'DD/MM/YYYY HH:mm:ss',
  ONLY_DATE: 'DD/MM/YYYY',
};

export const DATE_FORMAT_IN_JSON = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT_IN_JSON = 'YYYY-MM-DD HH:mm:ss';
export const TIME_FORMAT_IN_JSON = 'HH:mm:ss';

export const MAT_MOMENT_DATE_FORMATS: any = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MAT_MOMENT_DATE_FORMATS_IN_JSON: any = {
  parse: {
    dateInput: DATE_FORMAT_IN_JSON,
  },
  display: {
    dateInput: DATE_FORMAT_IN_JSON,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: DATE_FORMAT_IN_JSON,
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
