import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const SubscriptionPlan = React.lazy(
  () => import('../../../modules/home/SubscriptionPlan'),
);

const RegisterService = React.lazy(
  () => import('../../../modules/home/RegisterService'),
);
export const homeConfig = [
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/home/subscription-plan',
    element: <SubscriptionPlan />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XSUB_DEFAULT,
    path: '/home/register-service',
    element: <RegisterService />,
  },
];
