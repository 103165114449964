import {IntlShape, useIntl} from 'react-intl';
import {ReactNode} from 'react';
import {APP_CONFIG} from '@crema/constants/AppConfig';
import {AppStateConstant} from '@crema/constants/AppState';
import {MOMENT_DATE_FORMAT} from '@crema/config/MomentDateFormat.config';
import moment from 'moment';
import {ProductLevel} from '@crema/constants/AppConst';

export const getBreakPointsValue = (valueSet: any, breakpoint: any) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const isEmpty = (obj: any) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (
  products: any[],
  filters: any,
  stringKey = 'title',
) => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (filters[key].length === 0) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle: any) =>
          filters[key].includes(keyEle),
        );
      }
      if (filters[key]?.start || filters[key]?.end) {
        if (key === 'mrp') {
          return (
            product[key] >= filters[key].start &&
            product[key] < filters[key].end
          );
        } else {
          const start = new Date(filters[key].start).getTime();
          const end = new Date(filters[key].end).getTime();
          const productDate = new Date(product[key]).getTime();

          return productDate >= start && productDate <= end;
        }
      }
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

// 'intl' service singleton reference
let intl: IntlShape;
export const IntlGlobalProvider = ({children}: {children: ReactNode}): any => {
  intl = useIntl();
  // Keep the 'intl' service reference
  return children;
};

export const appIntl = () => {
  return intl;
};

export const generateRandomUniqueNumber = (): number => {
  const numbers: number[] = [];
  const number = Math.floor(Math.random() * 100000000);

  if (numbers.includes(number)) {
    return generateRandomUniqueNumber();
  } else {
    return number;
  }
};

export const isNotNull = (data) => {
  if (data == null || data === undefined) {
    return false;
  }
  if (data === '') {
    return false;
  }
  return true;
};

export const logoutApp = (isLogoutCognito: boolean) => {
  try {
    let language: string = localStorage.getItem('language');
    let version: string = localStorage.getItem('version');
    const urlCurrent: string = localStorage.getItem('urlCurrent');
    localStorage.clear();
    if (!language || language === 'null') {
      language = 'vi';
    }
    if (!version || version === 'null') {
      version = APP_CONFIG.VERSION;
    }
    if (!isLogoutCognito) {
      if (urlCurrent || urlCurrent !== 'null') {
        localStorage.setItem('urlCurrent', urlCurrent);
      }
    }
    localStorage.setItem('version', version);
    localStorage.setItem('language', language);
    window[AppStateConstant.appState] = null;
    sessionStorage.clear();
  } catch (e) {
    console.log(e);
  }
};

export const formatDateFullTime = (
  value: any,
  format: string = MOMENT_DATE_FORMAT.FULL_TIME,
): string => {
  if (value && (typeof value === 'number' || typeof value === 'string')) {
    const d: Date = new Date(value);
    const m: moment.Moment = moment(d);
    return m.format(format);
  } else if (value && value instanceof Date) {
    const m: moment.Moment = moment(value);
    return m.format(format);
  }
  return '';
};

export const isNullOrEmpty = (value) => {
  return (
    !value ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    value === ''
  );
};

export const getLocalStorage = (key: string) => {
  if (!key) return null;
  return JSON.parse(localStorage.getItem(key) || '{}');
};

// export const formatNumber = (num: any): string => {
//   return num.toLocaleString('en-US');
// };

export const formatNumber = (value: any) => {
  const num = parseFloat(value);
  return num.toLocaleString('en-US', {maximumFractionDigits: 0});
};

export const hasPermission = (functionName: string): boolean => {
  const appStatStorage = getAppState();
  if (!appStatStorage) {
    return false;
  }
  if (
    appStatStorage.user?.permissions &&
    appStatStorage.user?.permissions.length > 0
  ) {
    for (const per of appStatStorage.user.permissions) {
      if (per.target === functionName) {
        return true;
      }
    }
  }
  return false;
};

export const getAppState = () => {
  const appStatStorage = localStorage.getItem(AppStateConstant.appState);
  if (appStatStorage) {
    return JSON.parse(appStatStorage);
  }
  return null;
};

export const formatDate = (dateStr: string) => {
  if (!dateStr) return '';
  return moment(dateStr).format('DD/MM/YYYY');
};

export const getProductByPriority = (productList) => {
  if (productList?.length == 0) {
    return '';
  }
  let highestRole = null;
  let highestLevel = 0;

  productList?.forEach((productItem) => {
    const level = ProductLevel[productItem.value];
    if (level > highestLevel) {
      highestLevel = level;
      highestRole = productItem.value;
    }
  });

  return highestRole;
};

export const getTimestampLastYear = (): number => {
  const currentDate = new Date();
  const date = new Date();
  return date.setFullYear(currentDate.getFullYear() - 1);
};

export const getTimestampNextFiveYear = (): number => {
  const currentDate = new Date();
  const date = new Date();
  return date.setFullYear(currentDate.getFullYear() + 5);
};
