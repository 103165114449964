import React, {useCallback, useEffect} from 'react';
import {useUrlSearchParams} from 'use-url-search-params';
import AppContentView from '@crema/components/AppContentView';
import generateRoutes from '@crema/helpers/RouteGenerator';
import {Layouts} from '@crema/components/AppLayout';
import {useAuthUser} from '@crema/hooks/AuthHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '@crema/context/AppContextProvider/LayoutContextProvider';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../AppRoutes';
import {useRoutes} from 'react-router-dom';
import routesConfig from '../AppRoutes/routeConfig';
import AuthWrapper from './AuthWrapper';
import {useSidebarActionsContext} from '@crema/context/AppContextProvider/SidebarContextProvider';
import {
  RoleLevel,
  UserRoleEnum,
  authRole,
  initialUrl,
} from '@crema/constants/AppConst';
const getHighestRole = (userRoles) => {
  if (userRoles?.length == 0) {
    return '';
  }
  let highestRole = null;
  let highestLevel = 0;

  userRoles?.forEach((role) => {
    const roleLevel = RoleLevel[role];
    if (roleLevel > highestLevel) {
      highestLevel = roleLevel;
      highestRole = role;
    }
  });

  return highestRole;
};
const AppLayout = () => {
  const [params] = useUrlSearchParams();
  const loginUrl = `/verify-login?redirect=${window.location.pathname}`;
  const initURL = params?.redirect ? params?.redirect : initialUrl;
  const {navStyle} = useLayoutContext();
  const {user, isAuthenticated} = useAuthUser();
  const {updateNavStyle} = useLayoutActionsContext();
  const {updateMenuStyle, setSidebarBgImage} = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRoles:
      authRole[getHighestRole(user?.userRoles)] ||
      authRole?.[UserRoleEnum.DEFAULT], // to get the high priority role
    authorizedStructure: authorizedStructure(loginUrl),
    unAuthorizedStructure: unAuthorizedStructure(initURL),
    anonymousStructure: anonymousStructure(initURL),
  });
  const routes = useRoutes(generatedRoutes);
  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string);
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [
    params.layout,
    params.menuStyle,
    params.sidebarImage,
    updateNavStyle,
    updateMenuStyle,
    setSidebarBgImage,
  ]);
  const renderTemplate = useCallback(() => {
    const authorizedRouteConfig = routesConfig?.map((route) => ({
      ...route,
      children: route?.children?.filter((child) => {
        return authRole[getHighestRole(user?.userRoles)]?.includes(
          child?.permittedRole,
        );
      }),
    }));
    return (
      <>
        {isAuthenticated ? (
          <AppLayout routes={routes} routesConfig={authorizedRouteConfig} />
        ) : (
          <AuthWrapper>
            <AppContentView routes={routes} />
          </AuthWrapper>
        )}
      </>
    );
  }, [routes, user]);

  return renderTemplate();
};

export default AppLayout;
