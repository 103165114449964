import React, {createContext, useEffect, useState} from 'react';

export const CustomModalGlobalContext = createContext({});

export const CustomModalGlobalProvider = ({children}: any) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  return (
    <CustomModalGlobalContext.Provider
      value={{isShowModal, setIsShowModal, message, setMessage}}
    >
      {children}
    </CustomModalGlobalContext.Provider>
  );
};
