import styled from 'styled-components';

export const StyleHeaderConfirmModal = styled.div`
  display: flex;
  align-items: center;
`;

export const StyleIconTitle = styled.div`
  color: var(--color-warning);
  display: flex;
  margin-right: 0.25rem;
`;
