import React from 'react';
import {useSidebarContext} from '@crema/context/AppContextProvider/SidebarContextProvider';
import {StyleAppLogoText, StyledAppLogo} from './index.styled';
import {useIntl} from 'react-intl';

type AppLogoProps = {
  hasSidebarColor?: boolean;
};
const AppLogo: React.FC<AppLogoProps> = ({hasSidebarColor}) => {
  const {sidebarColorSet} = useSidebarContext();
  const {messages} = useIntl();
  return (
    <StyledAppLogo>
      {/* {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img src='/assets/images/logo-white-with-name.png' alt='crema-logo' />
      ) : (
        <img src='/assets/images/logo-with-name.png' alt='crema-logo' />
      )} */}
      <img src='/assets/images/logo_app.png' alt='subscription-logo' />
      <StyleAppLogoText>{messages['nameApp'] as string}</StyleAppLogoText>
    </StyledAppLogo>
  );
};

export default AppLogo;
