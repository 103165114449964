export const UserRoleEnum = Object.freeze({
  SUPER_ADMIN: 'ROLE_XSUB_SUPER_ADMIN',
  DEFAULT: 'ROLE_XSUB_DEFAULT',
});

export const RoleLevel = Object.freeze({
  [UserRoleEnum.SUPER_ADMIN]: 2,
  [UserRoleEnum.DEFAULT]: 1,
});

export const roleName = Object.freeze({
  [UserRoleEnum.SUPER_ADMIN]: 'Super Admin',
  [UserRoleEnum.DEFAULT]: 'Default',
});

export const authRole = Object.freeze({
  [UserRoleEnum.SUPER_ADMIN]: ['ROLE_XSUB_SUPER_ADMIN', 'ROLE_XSUB_DEFAULT'],
  [UserRoleEnum.DEFAULT]: ['ROLE_XSUB_DEFAULT'],
});

export const defaultUser: any = {
  uid: 'john-alex',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const allowMultiLanguage = process.env.REACT_APP_MULTILINGUAL === 'true';
export const fileStackKey = process.env.REACT_APP_FILESTACK_KEY as string;
export const initialUrl = process.env.REACT_APP_INITIAL_URL as string; // this url will open after login

export const Permission = Object.freeze({
  //Subscription Plan
  FUNCTION_GET_PRODUCT_TYPE_LIST: 'FUNCTION_GET_PRODUCT_TYPE_LIST',
  FUNCTION_GET_PACKAGE_LIST: 'FUNCTION_GET_PACKAGE_LIST',
  FUNCTION_DELETE_PACKAGE: 'FUNCTION_DELETE_PACKAGE',
  FUNCTION_GET_BILLING_LIST: 'FUNCTION_GET_BILLING_LIST',
  FUNCTION_GET_PAYMENT_LIST: 'FUNCTION_GET_PAYMENT_LIST',
  FUNCTION_CREATE_PACKAGE: 'FUNCTION_CREATE_PACKAGE',
});

export const ProductEnum = Object.freeze({
  ISIGN: 'ISIGN',
  XFILE: 'XFILE',
});

export const ProductLevel = Object.freeze({
  [ProductEnum.ISIGN]: 2,
  [ProductEnum.XFILE]: 1,
});
