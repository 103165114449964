import {LanguageProps} from '@crema/types/models/Apps';

const languageData: LanguageProps[] = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  // },
  // {
  //   languageId: 'spanish',
  //   locale: 'es',
  //   name: 'Spanish',
  // },
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  // },
  {
    languageId: 'vietnamese',
    locale: 'vi',
    name: 'Việt Nam',
    icon: 'vn',
  },
];
export default languageData;
