import React, {useContext, useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import {
  InfoViewActions,
  useInfoViewActionsContext,
} from '@crema/context/AppContextProvider/InfoViewContextProvider';
import {isRequestSuccessful, sanitizeData} from '@crema/helpers/ApiHelper';
import jwtAxios from '@crema/services/axios';
import {
  APIErrorProps,
  APIErrorResProps,
} from '@crema/types/models/APIDataProps';
import {AxiosResponse} from 'axios';
import {AppStateConstant} from '@crema/constants/AppState';
import {APP_CONFIG} from '@crema/constants/AppConfig';
import instance from '@crema/services/auth/api/auth-axios';
import {CustomModalGlobalContext} from '@crema/context/AppContextProvider/CustomModalGlobalProvider';
import {useIntl} from 'react-intl';
import {useAuthMethod} from './AuthHooks';
import {useNavigate} from 'react-router';
import {AnyMxRecord} from 'dns';
export const useGetDataApi = <T,>(
  url: string,
  initialData?: T,
  params?: any,
  initialCall?: boolean,
  callbackFun?: (data: T) => void,
) => {
  if (initialCall === undefined) {
    initialCall = true;
  }
  const {fetchError, showMessage} = useInfoViewActionsContext();
  const [initialUrl, setInitialUrl] = useState<string>(url);
  const [allowApiCall, setAllowApiCall] = useState<boolean>(initialCall);
  const [loading, setLoading] = useState<boolean>(initialCall);
  const [apiData, setData] = useState<T>(
    initialData ? initialData : (undefined as T),
  );
  const [queryParams, updateQueryParams] = useState<object>(params);
  const resStateRef = useRef<boolean>(false);
  const didCancelRef = useRef<boolean>(false);

  const updateInitialUrl = (value: string) => {
    setAllowApiCall(true);
    setInitialUrl(value);
  };

  const reCallAPI = () => {
    setQueryParams(queryParams);
  };

  const setQueryParams = (queryParams: object) => {
    setLoading(true);
    updateQueryParams({...queryParams});
    setAllowApiCall(true);
  };

  useEffect(() => {
    didCancelRef.current = false;
    const fetchData = () => {
      resStateRef.current = true;
      let params = {};
      if (!_.isEmpty(queryParams)) {
        params = {
          ...trimObjectValues(queryParams),
        };
      }
      jwtAxios
        .get(initialUrl, {params: sanitizeData(params)})
        .then((data: any) => {
          console.log(
            initialUrl,
            data.data,
            didCancelRef.current,
            isRequestSuccessful(data.status),
          );
          resStateRef.current = false;
          if (!didCancelRef.current) {
            if (isRequestSuccessful(data.status)) {
              setLoading(false);
              setData(data.data);
              if (callbackFun) callbackFun(data.data);
            } else {
              setLoading(false);
              console.log('Error', data.data);
              fetchError(data.data.error ? data.data.error : data.data.message);
              setData(initialData ? initialData : (undefined as T));
              if (callbackFun) callbackFun(data.data);
            }
          }
        })
        .catch((error: any) => {
          if (error.response.data.message) {
            console.log(initialUrl, error.response.data.message);
            if (callbackFun) callbackFun(error.response.data);
            fetchError(error.response.data.message);
          } else {
            fetchError(error.message);
            if (callbackFun) callbackFun(error);
          }
          setLoading(false);
        });
    };
    if (allowApiCall && !resStateRef.current) fetchData();
    return () => {
      didCancelRef.current = true;
    };
  }, [initialUrl, queryParams, allowApiCall]);
  return [
    {
      loading,
      apiData,
      initialUrl,
    },
    {
      setData,
      setLoading,
      updateInitialUrl,
      setQueryParams,
      reCallAPI,
    },
  ] as const;
};

export const trimObjectValues = (obj: any) => {
  if (_.isEmpty(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
  });
  return obj;
};

const handleApiResponse = <T,>(
  url: string,
  fetchSuccess: () => void,
  data: AxiosResponse<T>,
  resolve: (data: T) => void,
  reject: (data: APIErrorResProps) => void,
) => {
  fetchSuccess();
  if (isRequestSuccessful(data?.status)) {
    return resolve(data?.data);
  } else {
    return reject(data?.data as APIErrorResProps);
  }
};

const handleAPIError = (
  url: string,
  fetchSuccess: () => void,
  error: APIErrorProps,
  reject: (data: APIErrorResProps) => void,
) => {
  fetchSuccess();
  if (error?.response?.data?.message) {
    return reject(error?.response?.data);
  } else {
    return reject(error as APIErrorResProps);
  }
};

export const postDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload: object,
  isHideLoader?: boolean,
  headers = {},
): Promise<T> => {
  const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .post(url, sanitizeData(payload), headers ? {headers} : {})
      .then((data: any) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const putDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload: object,
  isHideLoader = false,
): Promise<T> => {
  const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .put(url, sanitizeData(payload))
      .then((data: any) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const getDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  params = {},
  isHideLoader = false,
  headers = {},
): Promise<T> => {
  // const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    // if (!isHideLoader) fetchStart?.();
    jwtAxios
      .get(url, {params: sanitizeData(params), headers})
      .then((data: any) => {
        return handleApiResponse<T>(url, () => {}, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, () => {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const deleteDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  params = {},
  isHideLoader = false,
): Promise<T> => {
  const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .delete(url, {params})
      .then((data: any) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload = {},
  isHideLoader = false,
  onUploadProgress = () => {
    console.log('');
  },
  allowDownload = false,
): Promise<T> => {
  const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .post(url, payload, {
        onUploadProgress,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        responseType: allowDownload ? 'arraybuffer' : 'stream',
      })
      .then((data: any) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadPutDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload = {},
  isHideLoader = false,
) => {
  const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .put(url, payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((data: any) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

// export const postRequestLogin = (url, payload): any => {
//   return new Promise((resolve, reject) => {
//     jwtAxios
//       .post(url, payload, {
//         headers: {
//           'content-type': 'application/json',
//         },
//       })
//       .then((data) => {
//         if (data.status === 200 && data.data) {
//           return resolve(data.data);
//         }
//         return resolve(data?.data);
//       })
//       .catch((error) => {
//         return reject(error);
//       });
//     return Promise.resolve();
//   });
// };

export const postRequestLogin = (url, payload): any => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, payload)
      .then((data) => {
        if (data) {
          return resolve(data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
    return Promise.resolve();
  });
};

// export const getRequestWithoutLoader = (url, headers) => {
//   let headersTemp = headers;
//   if (!headersTemp) {
//     headersTemp = {
//       'content-type': 'application/json',
//     };
//   }
//   headersTemp['Authorization'] =
//     APP_CONFIG.KEY_JWT + localStorage.getItem(AppStateConstant.ServerToken);
//   headersTemp['Accept'] = '*/*';
//   return new Promise((resolve, reject) => {
//     jwtAxios
//       .get(url, {
//         headers: headersTemp,
//       })
//       .then((data) => {
//         if (data.status === 200 && data.data) {
//           return resolve(data.data);
//         }
//         return resolve(data?.data);
//       })
//       .catch((error) => {
//         return reject(error);
//       });
//     return Promise.resolve();
//   });
// };

export const getRequestWithoutLoader = (url, params) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url)
      .then((data) => {
        if (data) {
          return resolve(data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
    return Promise.resolve();
  });
};

export const useAPIMethodGet = (
  initialUrl: any,
  initialCall: boolean = true,
  fetchError: (err: any) => void = (err) => {
    console.log('API Post error: ', err);
  },
  fetchSuccess: (response: any) => void = (response) => {},
) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [allowApiCall, setAllowApiCall] = useState<boolean>(initialCall);
  const [error, setError] = useState<any>(null);
  const [isRefetch, setIsRefetch] = useState({});
  const {setIsShowModal, setMessage} = useContext<any>(
    CustomModalGlobalContext,
  );
  const {messages} = useIntl();
  const navigate = useNavigate();

  const recallApi = () => {
    setAllowApiCall(true);
    setIsRefetch({});
  };
  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      instance
        .get(initialUrl?.current || initialUrl)
        .then((data) => {
          if (data) {
            setData(data);
            fetchSuccess(data);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            const message = `${
              messages['general.error.notFound_1.title'] as string
            }: <div style='font-weight:bold'>${
              initialUrl?.current || initialUrl
            }</div>`;
            const paramsMessage = {
              title: messages['general.error.title'] as string,
              message,
            };
            setIsShowModal(true);
            setMessage(paramsMessage);
            setError(error);
          } else if (error?.response?.status === 401) {
            const message = `${messages['general.error.401.title'] as string}`;
            const paramsMessage = {
              title: messages['general.error.title'] as string,
              message,
            };
            setIsShowModal(true);
            setMessage(paramsMessage);
            setTimeout(() => {
              localStorage.removeItem(AppStateConstant.ServerToken);
              localStorage.removeItem(AppStateConstant.appState);
              // window.close();
              navigate('/error-pages/error-404');
            }, 3000);
          }
          fetchError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (allowApiCall) {
      fetchData();
    }
  }, [isRefetch]);
  return {data, loading, error, recallApi};
};

export const useAPIMethodPost = (
  params: any,
  initialUrl: any,
  initialCall: boolean = true,
  fetchError: (err: any) => void = (err) => {
    console.log('API Post error: ', err);
  },
  fetchSuccess: (response: any) => void = (response) => {},
) => {
  const [data, setData] = useState<any>(null);
  const [queryParams, setQueryParams] = useState(params?.current || params);
  const [loading, setLoading] = useState<boolean>(false);
  const [allowApiCall, setAllowApiCall] = useState<boolean>(initialCall);
  const [isRefetch, setIsRefetch] = useState({});
  const {setIsShowModal, setMessage} = useContext<any>(
    CustomModalGlobalContext,
  );
  const {messages} = useIntl();
  const navigate = useNavigate();
  const recallApi = () => {
    setQueryParams(params?.current || params);
    setAllowApiCall(true);
    setIsRefetch({});
  };
  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      instance
        .post(
          initialUrl?.current || initialUrl,
          queryParams || JSON.stringify({}),
        )
        .then((data) => {
          if (data) {
            setData(data);
            fetchSuccess(data);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            const message = `${
              messages['general.error.notFound_1.title'] as string
            }: <div style='font-weight:bold'>${
              initialUrl?.current || initialUrl
            }</div>`;
            const paramsMessage = {
              title: messages['general.error.title'] as string,
              message,
            };
            setIsShowModal(true);
            setMessage(paramsMessage);
            // eslint-disable-next-line no-empty
          } else if (error?.response?.status === 401) {
            const message = `${messages['general.error.401.title'] as string}`;
            const paramsMessage = {
              title: messages['general.error.title'] as string,
              message,
            };
            setIsShowModal(true);
            setMessage(paramsMessage);
            setTimeout(() => {
              localStorage.removeItem(AppStateConstant.ServerToken);
              localStorage.removeItem(AppStateConstant.appState);
              // window.close();
              navigate('/error-pages/error-404');
            }, 3000);
          }
          fetchError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (allowApiCall) {
      fetchData();
    }
  }, [isRefetch]);
  return {data, loading, recallApi};
};

export const customGetDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  params = {},
  isHideLoader = false,
  headers = {},
): Promise<T> => {
  // const {fetchStart, fetchSuccess} = infoViewContext;
  return new Promise((resolve, reject) => {
    // if (!isHideLoader) fetchStart?.();
    instance
      .get(url, {params: sanitizeData(params), headers})
      .then((data: any) => {
        return handleApiResponse<T>(url, () => {}, data, resolve, reject);
      })
      .catch((error: any) => {
        return handleAPIError(url, () => {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const customPostDataApi = <T,>(
  url: string,
  payload: object,
  isHideLoader?: boolean,
  headers = {},
): Promise<T> => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, sanitizeData(payload), headers ? {headers} : {})
      .then((data: any) => {
        return resolve(data);
      })
      .catch((error: any) => {
        return reject(error);
      });
    return Promise.resolve();
  });
};
