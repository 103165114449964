import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import {FiLogIn} from 'react-icons/fi';
import {MdOutlineErrorOutline, MdOutlineWarningAmber} from 'react-icons/md';
import {
  StyleHeaderConfirmModal,
  StyleIconTitle,
} from '../AppConfirmModal/index.styled';
const AppMessageModal = ({paramsModal, callback}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (paramsModal?.isEnableModal) {
      setIsModalOpen(true);
    }
  }, [paramsModal]);

  const handleOk = () => {
    callback(true);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={
          <StyleHeaderConfirmModal>
            <StyleIconTitle>
              <MdOutlineErrorOutline />
            </StyleIconTitle>
            <span>{paramsModal?.title}</span>
          </StyleHeaderConfirmModal>
        }
        open={isModalOpen}
        footer={[
          <Button key='submit' type='primary' onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{__html: paramsModal?.message}} />
      </Modal>
    </>
  );
};

export default AppMessageModal;
