// import React from 'react';
// import {useInfoViewActionsContext} from '@crema/context/AppContextProvider/InfoViewContextProvider';
// import FirebaseAuthProvider from '@crema/services/auth/firebase/FirebaseAuthProvider';

// type AppAuthProviderProps = {
//   children: React.ReactNode;
// };

// const AppAuthProvider = ({children}: AppAuthProviderProps) => {
//   const {fetchStart, fetchSuccess, fetchError} = useInfoViewActionsContext();

//   return (
//     <FirebaseAuthProvider
//       fetchStart={fetchStart}
//       fetchError={fetchError}
//       fetchSuccess={fetchSuccess}
//     >
//       {children}
//     </FirebaseAuthProvider>
//   );
// };

// export default AppAuthProvider;

import React from 'react';
import {useInfoViewActionsContext} from '@crema/context/AppContextProvider/InfoViewContextProvider';
// import AWSAuthProvider from '@crema/services/auth/aws-cognito/AWSAuthProvider';

import PropTypes from 'prop-types';
import FirebaseAuthProvider from '@crema/services/auth/firebase/FirebaseAuthProvider';
import AuthProvider from '@crema/services/auth/auth-user/AuthUserProvider';

const AppAuthProvider = ({children}) => {
  const {fetchStart, fetchSuccess, fetchError, showMessage} =
    useInfoViewActionsContext();
  return (
    // <AWSAuthProvider
    //   fetchStart={fetchStart}
    //   fetchError={fetchError}
    //   fetchSuccess={fetchSuccess}
    // >
    //   {children}
    // </AWSAuthProvider>
    <AuthProvider
    // fetchStart={fetchStart}
    // fetchError={fetchError}
    // fetchSuccess={fetchSuccess}
    >
      {children}
    </AuthProvider>
  );
};

AppAuthProvider.propTypes = {
  children: PropTypes.node,
};
export default AppAuthProvider;
