// window['HOST_NAME_ISIGN'] = 'https://isign-api-uat.a4b.vn/';
// window['HOST_NAME_LOGIN_ISIGN'] = 'https://isign-api-uat.a4b.vn/';
// window['HOST_NAME_GW_ISIGN'] = 'https://isign-api-uat.a4b.vn/';
// window['HOST_NAME_ISIGN'] = 'https://isign-api.a4b.vn/';
// window['HOST_NAME_LOGIN_ISIGN'] = 'https://isign-api.a4b.vn/';
// window['HOST_NAME_GW_ISIGN'] = 'https://isign-api.a4b.vn/';
window['HOST_NAME_ISIGN'] = 'https://xplatform-api-uat.a4b.vn/';
window['HOST_NAME_LOGIN_ISIGN'] = 'https://xplatform-api-uat.a4b.vn/';
window['HOST_NAME_GW_ISIGN'] = 'https://xplatform-api-uat.a4b.vn/';
class NameConfig {
  static get HOST_NAME() {
    return window['HOST_NAME_ISIGN'];
  }

  static get HOST_NAME_LOGIN() {
    return window['HOST_NAME_LOGIN_ISIGN'];
  }

  static get PATH_SERVICE() {
    return this.HOST_NAME;
  }

  static get PATH_SERVICE_LOGIN() {
    return this.HOST_NAME + 'api/';
  }

  static get HOST_NAME_GW() {
    return window['HOST_NAME_GW_ISIGN'];
  }

  static get PATH_SERVICE_GW() {
    return this.HOST_NAME_GW + 'api/';
  }
}
export const APP_CONFIG = {
  LIMIT_QUERY: 20,
  QUERY_TIME_OUT: 30000,
  COMMAND_TIME_OUT: 30000,
  MAX_ELEMENT_IN_LIST: 1000,
  VERSION: process.env.REACT_APP_VERSION_APP,
  VERSION_HELP: '1.0',
  APP_CODE: 'SUBSCRIPTION',
  APP_NAME: 'Subscription',
  TENANT: 'VNG',
  COMPANY_CODE: 'A4B',
  COMPANY_NAME: 'A4B',
  GROW_HEIGHT_40: 40,
  GROW_HEIGHT_44: 44,
  GROW_HEIGHT_50: 50,
  HOST_NAME: NameConfig.PATH_SERVICE,
  HOST_NAME_LOGIN: NameConfig.PATH_SERVICE_LOGIN,
  HOST_NAME_GW: NameConfig.PATH_SERVICE_GW,
  KEY_JWT: 'Bearer ',
  SERVICE_REST: 'services/',
  LOGIN_SERVICE: 'authenticate',
  LOGOUT_SERVICE: 'j_spring_security_logout',
  LOGOUT: 'https://isign-api-uat.a4b.vn/' + 'logout',
  GET_MENU_TREE: 'menuByModule?module=home',
  GET_USERINFO: 'profileInfo',
  CHECK_LOGIN: 'identityServiceWrapper/checkLoggedIn',
  INPUT_TEXT_DEBOUNCE_TIME: 400,
  SCROLL_DEBOUNCE_TIME: 400,
  LANGUAGES: [
    {
      name: 'Vietnam',
      code: 'vi',
      locale: 'vi',
    },
    {
      name: 'English',
      code: 'en',
      locale: 'en-US',
    },
  ],
  LANGUAGE_LOCALE: {
    vi: 'vi',
    en: 'en-US',
  },
  DEFAULT_LANGUAGE: 'vi',
  ITEMS_PER_PAGE: [20, 50, 100, 200],
  ITEMS_FOR_SELECTION: 20,
  LANGUAGE_WEB: 'LANGUAGE_WEB',
  I18N_SERVICE: NameConfig.HOST_NAME + 'i18n/services/',
  MCRCUSTOMER_SERVICE: NameConfig.HOST_NAME + 'mcrcustomer/services/',
  EXCEL_API: NameConfig.HOST_NAME + 'mcrcustomer/api/excel/',
  MCRFORMIO_SERVICE: NameConfig.HOST_NAME + 'mcrformio/services/',
  DMS_SERVICE: NameConfig.HOST_NAME + 'dms/services/',
  DMS_RESOURCE: NameConfig.HOST_NAME + 'dms/',
  MCRNOTIFICATION: NameConfig.HOST_NAME + 'services/mcrnotification/',
  MCRMULTINOTIFICATIONS:
    NameConfig.HOST_NAME + 'mcrnotification/services/messageServices/',
  MCRTICKET_SERVICE: NameConfig.HOST_NAME + 'mcrticket/services/',
  MCRTICKET_API: NameConfig.HOST_NAME + 'mcrticket/api/',
  MCRTICKET_RPT_API: NameConfig.HOST_NAME + 'mcrticketrpt/api/',
  MCRTICKET_EXCEL_API: NameConfig.HOST_NAME + 'mcrticket/api/excel/',
  SECMT_API_SERVICE: NameConfig.HOST_NAME + 'mcrsecmt/services/',
  MCRSECMT_API: NameConfig.HOST_NAME + 'mcrsecmt/api/',
  MCRSUPPORTER: NameConfig.HOST_NAME + 'mcrsupporter/services/',
  CRM_TICKET: NameConfig.HOST_NAME + 'crmticket/',
  MCRUS_PROFILE: NameConfig.HOST_NAME + 'services/mcrusprofile/api/',
  MCRUS_PROFILE_API: NameConfig.HOST_NAME + 'mcrusprofile/api/',
  MCR_DMS: NameConfig.HOST_NAME + 'services/mcrisgdms/api/',
  ACTIVITY_APP: NameConfig.HOST_NAME + 'activitystreams/services/',
  SOCMS: NameConfig.HOST_NAME + 'socms/services/',
  MCRBILLING: NameConfig.HOST_NAME + 'mcrbilling/services/',
  CRMRPT_SERVICE: NameConfig.HOST_NAME + 'mcr-crmrpt/api/ca/',
  CRMRPT_SC_SERVICE: NameConfig.HOST_NAME + 'mcr-crmrpt/api/sc/',
  MCR_CLOUD: NameConfig.HOST_NAME + 'mcrcloud/services/',
  MCR_EVENT: NameConfig.HOST_NAME + 'mcrevent/services/',
  MCRCRMRPT: NameConfig.HOST_NAME + 'mcrcrmrpt/',
  MCRFEA: NameConfig.HOST_NAME + 'mcrfea/services/',
  MCRJOB: NameConfig.HOST_NAME + 'mcrjob/api/',
  MCRISGCUS: NameConfig.HOST_NAME + 'services/mcrisgcus/api/',
  MCREVL: NameConfig.HOST_NAME + 'services/mcrevl/api/',
  MCR_US_PROFILE: NameConfig.HOST_NAME + 'services/mcrusprofile/api/',
  MCR_IPT: NameConfig.HOST_NAME + 'services/mcript/api/',
  MCR_EVL_RU: NameConfig.HOST_NAME + 'services/mcrevlru/api/',
  MCR_EVL_VF: NameConfig.HOST_NAME + 'services/mcrevlvf/api/',
  MCR_SIG: NameConfig.HOST_NAME + 'services/mcrsig/api/',
  MCR_SECMT_API: NameConfig.HOST_NAME + 'services/mcrsecmt/api/',
  MCRUS_PROFILE_PUB: NameConfig.HOST_NAME + 'services/mcrusprofile/pub/',
  MCR_ISG_DBRP: NameConfig.HOST_NAME + 'services/mcrisgdbrp/api/dashboard/',
  MCR_ISG_JOB: NameConfig.HOST_NAME + 'services/mcrisgjob/api/job/',
  MCR_ISG_DBRP_SERVICE: NameConfig.HOST_NAME + 'services/mcrisgdbrp/api/',
  MCR_BULK_UPLOAD: NameConfig.HOST_NAME + 'services/mcrbulkupload/api/',
  MCR_TEMPLATE: NameConfig.HOST_NAME + 'services/mcrtemplate/api/',
  ZMO: NameConfig.HOST_NAME + 'services/mcrisip/api/',
  MCR_OUA: NameConfig.HOST_NAME + 'services/mcroua/api/',
  SERVICE_LOG: NameConfig.HOST_NAME + 'services/servicelog/api/',
  XPSUB: NameConfig.HOST_NAME + 'services/xpsub/api/',
};
export const API_URL = {
  ATTRIBUTE: {
    GET: APP_CONFIG.HOST_NAME_LOGIN + 'getAttributeValueOfUser',
    SET: APP_CONFIG.HOST_NAME_LOGIN + 'setAttributeValueForUser',
  },
  USER_JOB: {
    SEARCH_USER_JOBS: APP_CONFIG.MCRJOB + 'job/searchUserJobs',
    PIVOT_COUNT: APP_CONFIG.MCRJOB + 'job/pivotCount',
  },
  ENVELOPS: {
    QUICK_SEARCH_CONTACT: APP_CONFIG.MCRISGCUS + 'contact/quickSearch',
    GET_ENVELOPE_CATEGORY: APP_CONFIG.MCREVL + 'category/listEnvelopeCategory',
    GET_FIELD_TYPE: APP_CONFIG.MCREVL + 'category/listFieldType',
    GET_VERIFICATION: APP_CONFIG.MCREVL + 'category/listVerification',
    GET_SIGNATURE_TYPE: APP_CONFIG.MCREVL + 'category/listSignatureType',
    GET_AUTHORIZATION: APP_CONFIG.MCREVL + 'category/listAuthorization',
    GET_AUTHENTICATION: APP_CONFIG.MCREVL + 'category/listAuthentication',
    GET_ORDER_TYPE: APP_CONFIG.MCREVL + 'category/listOrderType',
    GET_NOTIFICATION: APP_CONFIG.MCREVL + 'category/listNotification',
    GET_CURRENT_USER_TENANTS:
      APP_CONFIG.MCR_US_PROFILE + 'employee/getCurrentUserTenants',
    CHANGE_TENANT: NameConfig.HOST_NAME + 'api/auth/changeTenant',
  },
};
export const LOGIN_URL = {
  LOGIN: APP_CONFIG.HOST_NAME_LOGIN + 'authenticate',
  MENU: APP_CONFIG.HOST_NAME_LOGIN + 'menuByModule?module=home',
  USER_INFO: APP_CONFIG.HOST_NAME_LOGIN + 'profileInfo',
  LOG_OUT: APP_CONFIG.HOST_NAME_LOGIN + 'logout',
  GET_TOKEN: APP_CONFIG.HOST_NAME_GW + 'authenticateWithToken',
  GET_PROFILE_NEW: APP_CONFIG.HOST_NAME_GW + 'profileInfo',
  CHECK_LOGIN: APP_CONFIG.HOST_NAME_GW + 'pub/authen/config?email=',
  GET_TOKEN_AUTHENTICATE: APP_CONFIG.HOST_NAME_GW + 'authenticate',
};
